// 正式
module.exports = {
  title: 'E查工程',
  // baseUrl: 'http://web.huachuangiot.com/', // 153地址
  // baseApi: 'http://web.huachuangiot.com/engineeringCreditService', // 153api请求地址
  // baseUrl: 'https://echa.yixinxm.com', // 正式项目地址
  // baseApi: 'https://echa.yixinxm.com/engineeringCreditService', // 正式api请求地址
  // baseUrl: 'http://192.168.200.233/', // 本地测试
  // baseApi: 'http://192.168.200.233/engineeringCreditService', // 本地测试
  baseUrl: 'https://xyfpc.huachuangiot.com/', // 153地址
  baseApi: 'https://xyfpc.huachuangiot.com/engineeringCreditService', // 153api请求地址
  APPID: 'xxx',
  APPSECRET: 'xxx',
  $cdn: 'https://imgs.solui.cn'
}
